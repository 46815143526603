<template>
  <div class="formation">
    <liste_organisation/>
    
  </div>
</template>

<script>
// @ is an alias to /src

import liste_organisation from '../components/l_organisation.vue'

export default {
  name: 'Organisationdetail',
  components: {
    liste_organisation,
  }
}
</script>
